import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}
export class AppTheme {
  public constructor(private theme: ThemeType) {}

  public setTheme(themeToSet: ThemeType) {
    this.theme = themeToSet;
  }
  public isDarkTheme(): boolean {
    return this.theme === ThemeType.Dark;
  }
}
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _currentTheme: AppTheme = this.getCurrentTheme();
  private _currentTheme$: BehaviorSubject<AppTheme>;
  public currentTheme$: Observable<AppTheme>;

  public constructor() {
    this._currentTheme$ = new BehaviorSubject<AppTheme>(this._currentTheme);
    this.currentTheme$ = this._currentTheme$.asObservable();
  }

  public toggleTheme(): void {
    if (this._currentTheme.isDarkTheme()) {
      this.setLightTheme();
    } else {
      this.setDarkTheme();
    }
  }

  private setLightTheme(): void {
    localStorage.setItem('theme', ThemeType.Light);
    document.documentElement.classList.remove('dark');
    this._currentTheme.setTheme(ThemeType.Light);
    this._currentTheme$.next(this._currentTheme);
  }

  private setDarkTheme(): void {
    localStorage.setItem('theme', ThemeType.Dark);
    document.documentElement.classList.add('dark');
    this._currentTheme.setTheme(ThemeType.Dark);
    this._currentTheme$.next(this._currentTheme);
  }

  private getCurrentTheme(): AppTheme {
    return new AppTheme((localStorage.getItem('theme') as ThemeType) ?? ThemeType.Light);
  }
}
